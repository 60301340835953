<template>
	<div id="token-timeout-warning" :class="{ 'token-timeout-visible': timeoutWarningActive }">
		Due to inactivity, your session will end in <span class="token-timeout-time">{{ timeRemaining }}</span> seconds.
		<v-btn @click="refreshSession">Stay Logged In</v-btn>
		<v-btn outlined @click="logout">Logout</v-btn>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: 'tokenManager',
	data() {
		return {
			timeoutWarningActive: false,
			lastActive: Date.now(),
			countdownInterval: null,
			authTimeout: null,
			timeRemaining: null,
			warningTimer: null,
			gettingNewSession: false,
		}
	},
	computed: {
		...mapGetters({
			accessToken: 'getAccessToken',
			accessTokenExp: 'getAccessTokenExpiration',
			accessTokenIss: 'getAccessTokenIssueTime',
		}),
	},
	watch: {
		lastActive() {
			// do nothing, this just makes the dev tools update instantly
		},
		warningTimer() {
			// again, just making sure the dev tools update instantly
		},
	},
	created() {
		window.addEventListener('keydown', this.activeUpdate);
		window.addEventListener('click', this.activeUpdate);
		if (!this.gettingNewSession) {
			this.createTimer();
		}
	},
	methods: {
		createTimer() {
			// if we get here and somehow the expiration has already passed, or there's no token, logout immediately
			if (!this.accessToken || this.accessTokenExp - Date.now() < 0) {
				console.error('No valid token found! Ending session');
				this.logout();
				// make sure we don't continue processing
				return null
			}

			// set a timer within 30 seconds of expiration
			let timeToSessionWarning = (this.accessTokenExp - 30000) - Date.now();
			this.warningTimer = timeToSessionWarning < 1000 ? 1000 : timeToSessionWarning;
			console.info('Session expires in ' + ((timeToSessionWarning / 1000 + 30) / 60).toFixed(2) + ' minutes');

			let that = this;

			this.authTimeout = setTimeout(function () {

				// auto-refresh if the user was active since the token was issued
				if (that.lastActive > that.accessTokenIss) {
					console.info('Auto-refreshing session');
					that.refreshSession();
				} else {
					// console.log('issued idle warning');
					that.timeoutWarningActive = true;
					that.timeRemaining = 30;
					that.countdownInterval = setInterval(that.countdownTimeRemaining, 1000);
				}
			}, this.warningTimer);
		},
		activeUpdate() {
			this.lastActive = Date.now();
		},
		cleanupTimers() {
			this.timeoutWarningActive = false;
			this.timeRemaining = null;
			this.warningTimer = null;
			clearInterval(this.countdownInterval);
			this.countdownInterval = null;
			clearTimeout(this.authTimeout);
			this.authTimeout = null;
		},
		async refreshSession() {
			let oldToken = this.accessToken;
			this.gettingNewSession = true; // prevents new timers until we're ready

			this.cleanupTimers();
			await this.$store.dispatch('refreshSession');

			if (this.accessToken === oldToken) {
				console.warn('Failed to get a new token during refresh.');
			}

			await this.$store.dispatch("getRefreshToken");
			await this.$store.dispatch('getLoggedInUserInfo');
			await this.$store.dispatch('getTenantList');
			this.createTimer();
			this.gettingNewSession = false;
		},
		countdownTimeRemaining() {
			if (this.timeRemaining !== null && this.timeoutWarningActive) {
				if (this.timeRemaining < 1) {
					console.info('Forced logout due to session expiration');
					this.logout();
				}
				this.timeRemaining--;
			} else {
				console.warn('Attempted to countdown on null timer.');
			}
		},
		logout() {
			this.cleanupTimers();
			this.$store.dispatch('logout');
		},
	},
	beforeDestroy() {
		this.cleanupTimers();
	}
};
</script>

<style lang="scss" scoped>
#token-timeout-warning {
	font-size: 1.5em;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 2em;
	text-align: center;
	background-color: #BBBDC5;
	border-top: 1em solid #AE2727;
	transform: translate(0, 100%);
	transition: translate 1s ease-in;

	.v-btn {
		margin-left: 2em;
	}

	&.token-timeout-visible {
		transform: translate(0, 0);
	}
}
</style>
